export default [
  {
    key: 'AA',
    name: 'Afar',
    originalName: 'Afar'
  },
  {
    key: 'AB',
    name: 'Abkhazian',
    originalName: 'Аҧсуа'
  },
  {
    key: 'AF',
    name: 'Afrikaans',
    originalName: 'Afrikaans'
  },
  {
    key: 'AK',
    name: 'Akan',
    originalName: 'Akana'
  },
  {
    key: 'AM',
    name: 'Amharic',
    originalName: 'አማርኛ'
  },
  {
    key: 'AN',
    name: 'Aragonese',
    originalName: 'Aragonés'
  },
  {
    key: 'AR',
    name: 'Arabic',
    originalName: 'العربية'
  },
  {
    key: 'AS',
    name: 'Assamese',
    originalName: 'অসমীয়া'
  },
  {
    key: 'AV',
    name: 'Avar',
    originalName: 'Авар'
  },
  {
    key: 'AY',
    name: 'Aymara',
    originalName: 'Aymar'
  },
  {
    key: 'AZ',
    name: 'Azerbaijani',
    originalName: 'Azərbaycanca / آذربايجان'
  },
  {
    key: 'BA',
    name: 'Bashkir',
    originalName: 'Башҡорт'
  },
  {
    key: 'BE',
    name: 'Belarusian',
    originalName: 'Беларуская'
  },
  {
    key: 'BG',
    name: 'Bulgarian',
    originalName: 'Български'
  },
  {
    key: 'BH',
    name: 'Bihari',
    originalName: 'भोजपुरी'
  },
  {
    key: 'BI',
    name: 'Bislama',
    originalName: 'Bislama'
  },
  {
    key: 'BM',
    name: 'Bambara',
    originalName: 'Bamanankan'
  },
  {
    key: 'BN',
    name: 'Bengali',
    originalName: 'বাংলা'
  },
  {
    key: 'BO',
    name: 'Tibetan',
    originalName: 'བོད་ཡིག / Bod skad'
  },
  {
    key: 'BR',
    name: 'Breton',
    originalName: 'Brezhoneg'
  },
  {
    key: 'BS',
    name: 'Bosnian',
    originalName: 'Bosanski'
  },
  {
    key: 'CA',
    name: 'Catalan',
    originalName: 'Català'
  },
  {
    key: 'CE',
    name: 'Chechen',
    originalName: 'Нохчийн'
  },
  {
    key: 'CH',
    name: 'Chamorro',
    originalName: 'Chamoru'
  },
  {
    key: 'CO',
    name: 'Corsican',
    originalName: 'Corsu'
  },
  {
    key: 'CR',
    name: 'Cree',
    originalName: 'Nehiyaw'
  },
  {
    key: 'CS',
    name: 'Czech',
    originalName: 'Česky'
  },
  {
    key: 'CU',
    name: 'Old Church Slavonic / Old Bulgarian',
    originalName: 'словѣньскъ / slověnĭskŭ'
  },
  {
    key: 'CV',
    name: 'Chuvash',
    originalName: 'Чăваш'
  },
  {
    key: 'CY',
    name: 'Welsh',
    originalName: 'Cymraeg'
  },
  {
    key: 'DA',
    name: 'Danish',
    originalName: 'Dansk'
  },
  {
    key: 'DE',
    name: 'German',
    originalName: 'Deutsch'
  },
  {
    key: 'DZ',
    name: 'Dzongkha',
    originalName: 'ཇོང་ཁ'
  },
  {
    key: 'EE',
    name: 'Ewe',
    originalName: 'Ɛʋɛ'
  },
  {
    key: 'EL',
    name: 'Greek',
    originalName: 'Ελληνικά'
  },
  {
    key: 'EN',
    name: 'English',
    originalName: 'English'
  },
  {
    key: 'EO',
    name: 'Esperanto',
    originalName: 'Esperanto'
  },
  {
    key: 'ES',
    name: 'Spanish',
    originalName: 'Español'
  },
  {
    key: 'ET',
    name: 'Estonian',
    originalName: 'Eesti'
  },
  {
    key: 'EU',
    name: 'Basque',
    originalName: 'Euskara'
  },
  {
    key: 'FF',
    name: 'Peul',
    originalName: 'Fulfulde'
  },
  {
    key: 'FI',
    name: 'Finnish',
    originalName: 'Suomi'
  },
  {
    key: 'FJ',
    name: 'Fijian',
    originalName: 'Na Vosa Vakaviti'
  },
  {
    key: 'FO',
    name: 'Faroese',
    originalName: 'Føroyskt'
  },
  {
    key: 'FR',
    name: 'French',
    originalName: 'Français'
  },
  {
    key: 'FY',
    name: 'West Frisian',
    originalName: 'Frysk'
  },
  {
    key: 'GA',
    name: 'Irish',
    originalName: 'Gaeilge'
  },
  {
    key: 'GD',
    name: 'Scottish Gaelic',
    originalName: 'Gàidhlig'
  },
  {
    key: 'GL',
    name: 'Galician',
    originalName: 'Galego'
  },
  {
    key: 'GN',
    name: 'Guarani',
    originalName: 'Avañe\'ẽ'
  },
  {
    key: 'GU',
    name: 'Gujarati',
    originalName: 'ગુજરાતી'
  },
  {
    key: 'GV',
    name: 'Manx',
    originalName: 'Gaelg'
  },
  {
    key: 'HI',
    name: 'Hindi',
    originalName: 'हिन्दी'
  },
  {
    key: 'HO',
    name: 'Hiri Motu',
    originalName: 'Hiri Motu'
  },
  {
    key: 'HR',
    name: 'Croatian',
    originalName: 'Hrvatski'
  },
  {
    key: 'HT',
    name: 'Haitian',
    originalName: 'Krèyol ayisyen'
  },
  {
    key: 'HU',
    name: 'Hungarian',
    originalName: 'Magyar'
  },
  {
    key: 'HY',
    name: 'Armenian',
    originalName: 'Հայերեն'
  },
  {
    key: 'HZ',
    name: 'Herero',
    originalName: 'Otsiherero'
  },
  {
    key: 'IA',
    name: 'Interlingua',
    originalName: 'Interlingua'
  },
  {
    key: 'ID',
    name: 'Indonesian',
    originalName: 'Bahasa Indonesia'
  },
  {
    key: 'IE',
    name: 'Interlingue',
    originalName: 'Interlingue'
  },
  {
    key: 'IG',
    name: 'Igbo',
    originalName: 'Igbo'
  },
  {
    key: 'II',
    name: 'Sichuan Yi',
    originalName: 'ꆇꉙ / 四川彝语'
  },
  {
    key: 'IK',
    name: 'Inupiak',
    originalName: 'Iñupiak'
  },
  {
    key: 'IO',
    name: 'Ido',
    originalName: 'Ido'
  },
  {
    key: 'IS',
    name: 'Icelandic',
    originalName: 'Íslenska'
  },
  {
    key: 'IT',
    name: 'Italian',
    originalName: 'Italiano'
  },
  {
    key: 'IU',
    name: 'Inuktitut',
    originalName: 'ᐃᓄᒃᑎᑐᑦ'
  },
  {
    key: 'JA',
    name: 'Japanese',
    originalName: '日本語'
  },
  {
    key: 'JV',
    name: 'Javanese',
    originalName: 'Basa Jawa'
  },
  {
    key: 'KA',
    name: 'Georgian',
    originalName: 'ქართული'
  },
  {
    key: 'KG',
    name: 'Kongo',
    originalName: 'KiKongo'
  },
  {
    key: 'KI',
    name: 'Kikuyu',
    originalName: 'Gĩkũyũ'
  },
  {
    key: 'KJ',
    name: 'Kuanyama',
    originalName: 'Kuanyama'
  },
  {
    key: 'KK',
    name: 'Kazakh',
    originalName: 'Қазақша'
  },
  {
    key: 'KL',
    name: 'Greenlandic',
    originalName: 'Kalaallisut'
  },
  {
    key: 'KM',
    name: 'Cambodian',
    originalName: 'ភាសាខ្មែរ'
  },
  {
    key: 'KN',
    name: 'Kannada',
    originalName: 'ಕನ್ನಡ'
  },
  {
    key: 'KO',
    name: 'Korean',
    originalName: '한국어'
  },
  {
    key: 'KR',
    name: 'Kanuri',
    originalName: 'Kanuri'
  },
  {
    key: 'KV',
    name: 'Komi',
    originalName: 'Коми'
  },
  {
    key: 'KW',
    name: 'Cornish',
    originalName: 'Kernewek'
  },
  {
    key: 'KY',
    name: 'Kirghiz',
    originalName: 'Kırgızca / Кыргызча'
  },
  {
    key: 'LA',
    name: 'Latin',
    originalName: 'Latina'
  },
  {
    key: 'LB',
    name: 'Luxembourgish',
    originalName: 'Lëtzebuergesch'
  },
  {
    key: 'LG',
    name: 'Ganda',
    originalName: 'Luganda'
  },
  {
    key: 'LI',
    name: 'Limburgian',
    originalName: 'Limburgs'
  },
  {
    key: 'LN',
    name: 'Lingala',
    originalName: 'Lingála'
  },
  {
    key: 'LO',
    name: 'Laotian',
    originalName: 'ລາວ / Pha xa lao'
  },
  {
    key: 'LT',
    name: 'Lithuanian',
    originalName: 'Lietuvių'
  },
  {
    key: 'LV',
    name: 'Latvian',
    originalName: 'Latviešu'
  },
  {
    key: 'MG',
    name: 'Malagasy',
    originalName: 'Malagasy'
  },
  {
    key: 'MH',
    name: 'Marshallese',
    originalName: 'Kajin Majel / Ebon'
  },
  {
    key: 'MI',
    name: 'Maori',
    originalName: 'Māori'
  },
  {
    key: 'MK',
    name: 'Macedonian',
    originalName: 'Македонски'
  },
  {
    key: 'ML',
    name: 'Malayalam',
    originalName: 'മലയാളം'
  },
  {
    key: 'MN',
    name: 'Mongolian',
    originalName: 'Монгол'
  },
  {
    key: 'MO',
    name: 'Moldovan',
    originalName: 'Moldovenească'
  },
  {
    key: 'MR',
    name: 'Marathi',
    originalName: 'मराठी'
  },
  {
    key: 'MS',
    name: 'Malay',
    originalName: 'Bahasa Melayu'
  },
  {
    key: 'MT',
    name: 'Maltese',
    originalName: 'bil-Malti'
  },
  {
    key: 'MY',
    name: 'Burmese',
    originalName: 'Myanmasa'
  },
  {
    key: 'NA',
    name: 'Nauruan',
    originalName: 'Dorerin Naoero'
  },
  {
    key: 'ND',
    name: 'North Ndebele',
    originalName: 'Sindebele'
  },
  {
    key: 'NE',
    name: 'Nepali',
    originalName: 'नेपाली'
  },
  {
    key: 'NG',
    name: 'Ndonga',
    originalName: 'Oshiwambo'
  },
  {
    key: 'NL',
    name: 'Dutch',
    originalName: 'Nederlands'
  },
  {
    key: 'NN',
    name: 'Norwegian Nynorsk',
    originalName: 'Norsk (nynorsk)'
  },
  {
    key: 'NO',
    name: 'Norwegian',
    originalName: 'Norsk (bokmål / riksmål)'
  },
  {
    key: 'NR',
    name: 'South Ndebele',
    originalName: 'isiNdebele'
  },
  {
    key: 'NV',
    name: 'Navajo',
    originalName: 'Diné bizaad'
  },
  {
    key: 'NY',
    name: 'Chichewa',
    originalName: 'Chi-Chewa'
  },
  {
    key: 'OC',
    name: 'Occitan',
    originalName: 'Occitan'
  },
  {
    key: 'OJ',
    name: 'Ojibwa',
    originalName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin'
  },
  {
    key: 'OM',
    name: 'Oromo',
    originalName: 'Oromoo'
  },
  {
    key: 'OR',
    name: 'Oriya',
    originalName: 'ଓଡ଼ିଆ'
  },
  {
    key: 'OS',
    name: 'Ossetian / Ossetic',
    originalName: 'Иронау'
  },
  {
    key: 'PA',
    name: 'Panjabi / Punjabi',
    originalName: 'ਪੰਜਾਬੀ / पंजाबी / پنجابي'
  },
  {
    key: 'PI',
    name: 'Pali',
    originalName: 'Pāli / पाऴि'
  },
  {
    key: 'PL',
    name: 'Polish',
    originalName: 'Polski'
  },
  {
    key: 'PT',
    name: 'Portuguese',
    originalName: 'Português'
  },
  {
    key: 'QU',
    name: 'Quechua',
    originalName: 'Runa Simi'
  },
  {
    key: 'RM',
    name: 'Raeto Romance',
    originalName: 'Rumantsch'
  },
  {
    key: 'RN',
    name: 'Kirundi',
    originalName: 'Kirundi'
  },
  {
    key: 'RO',
    name: 'Romanian',
    originalName: 'Română'
  },
  {
    key: 'RU',
    name: 'Russian',
    originalName: 'Русский'
  },
  {
    key: 'RW',
    name: 'Rwandi',
    originalName: 'Kinyarwandi'
  },
  {
    key: 'SA',
    name: 'Sanskrit',
    originalName: 'संस्कृतम्'
  },
  {
    key: 'SC',
    name: 'Sardinian',
    originalName: 'Sardu'
  },
  {
    key: 'SD',
    name: 'Sindhi',
    originalName: 'सिनधि'
  },
  {
    key: 'SE',
    name: 'Northern Sami',
    originalName: 'Sámegiella'
  },
  {
    key: 'SG',
    name: 'Sango',
    originalName: 'Sängö'
  },
  {
    key: 'SH',
    name: 'Serbo-Croatian',
    originalName: 'Srpskohrvatski / Српскохрватски'
  },
  {
    key: 'SI',
    name: 'Sinhalese',
    originalName: 'සිංහල'
  },
  {
    key: 'SK',
    name: 'Slovak',
    originalName: 'Slovenčina'
  },
  {
    key: 'SL',
    name: 'Slovenian',
    originalName: 'Slovenščina'
  },
  {
    key: 'SM',
    name: 'Samoan',
    originalName: 'Gagana Samoa'
  },
  {
    key: 'SN',
    name: 'Shona',
    originalName: 'chiShona'
  },
  {
    key: 'SO',
    name: 'Somalia',
    originalName: 'Soomaaliga'
  },
  {
    key: 'SQ',
    name: 'Albanian',
    originalName: 'Shqip'
  },
  {
    key: 'SR',
    name: 'Serbian',
    originalName: 'Српски'
  },
  {
    key: 'SS',
    name: 'Swati',
    originalName: 'SiSwati'
  },
  {
    key: 'ST',
    name: 'Southern Sotho',
    originalName: 'Sesotho'
  },
  {
    key: 'SU',
    name: 'Sundanese',
    originalName: 'Basa Sunda'
  },
  {
    key: 'SV',
    name: 'Swedish',
    originalName: 'Svenska'
  },
  {
    key: 'SW',
    name: 'Swahili',
    originalName: 'Kiswahili'
  },
  {
    key: 'TA',
    name: 'Tamil',
    originalName: 'தமிழ்'
  },
  {
    key: 'TE',
    name: 'Telugu',
    originalName: 'తెలుగు'
  },
  {
    key: 'TG',
    name: 'Tajik',
    originalName: 'Тоҷикӣ'
  },
  {
    key: 'TH',
    name: 'Thai',
    originalName: 'ไทย / Phasa Thai'
  },
  {
    key: 'TI',
    name: 'Tigrinya',
    originalName: 'ትግርኛ'
  },
  {
    key: 'TK',
    name: 'Turkmen',
    originalName: 'Туркмен / تركمن'
  },
  {
    key: 'TL',
    name: 'Tagalog / Filipino',
    originalName: 'Tagalog'
  },
  {
    key: 'TN',
    name: 'Tswana',
    originalName: 'Setswana'
  },
  {
    key: 'TO',
    name: 'Tonga',
    originalName: 'Lea Faka-Tonga'
  },
  {
    key: 'TR',
    name: 'Turkish',
    originalName: 'Türkçe'
  },
  {
    key: 'TS',
    name: 'Tsonga',
    originalName: 'Xitsonga'
  },
  {
    key: 'TT',
    name: 'Tatar',
    originalName: 'Tatarça'
  },
  {
    key: 'TW',
    name: 'Twi',
    originalName: 'Twi'
  },
  {
    key: 'TY',
    name: 'Tahitian',
    originalName: 'Reo Mā`ohi'
  },
  {
    key: 'UG',
    name: 'Uyghur',
    originalName: 'Uyƣurqə / ئۇيغۇرچە'
  },
  {
    key: 'UK',
    name: 'Ukrainian',
    originalName: 'Українська'
  },
  {
    key: 'UZ',
    name: 'Uzbek',
    originalName: 'Ўзбек'
  },
  {
    key: 'VE',
    name: 'Venda',
    originalName: 'Tshivenḓa'
  },
  {
    key: 'VI',
    name: 'Vietnamese',
    originalName: 'Tiếng Việt'
  },
  {
    key: 'VO',
    name: 'Volapük',
    originalName: 'Volapük'
  },
  {
    key: 'WA',
    name: 'Walloon',
    originalName: 'Walon'
  },
  {
    key: 'WO',
    name: 'Wolof',
    originalName: 'Wollof'
  },
  {
    key: 'XH',
    name: 'Xhosa',
    originalName: 'isiXhosa'
  },
  {
    key: 'YO',
    name: 'Yoruba',
    originalName: 'Yorùbá'
  },
  {
    key: 'ZA',
    name: 'Zhuang',
    originalName: 'Cuengh / Tôô / 壮语'
  },
  {
    key: 'ZH',
    name: 'Chinese',
    originalName: '中文'
  },
  {
    key: 'ZU',
    name: 'Zulu',
    originalName: 'isiZulu'
  }
];
